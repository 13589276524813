import React from 'react'
import {Navigate} from 'react-router-dom';
import {useAuth} from '../contexts/AuthContext';

const PublicRoute = ({children}) => {

    const {user} = useAuth();

    if (user){
        return <Navigate to='/account' />;
    }
  return children;
}

export default PublicRoute