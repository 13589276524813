import React, { useState, useEffect } from 'react';
import { imageDb } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { setDoc, doc, collection, getDoc, getDocs } from "firebase/firestore";
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { db } from '../firebase';

const ImageUpload = () => {
    const [img, setImg] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const {user, userid, logout} = useAuth();
    const [imgLoading, setImgLoading] = useState(true);
    const [imgLoadingProcess, setImgLoadingProcess] = useState(false);

    const collectionRef = collection(db, 'innovationsmeile');

    const handleImgUpload = (e) => {
        e.preventDefault();
        if(img !== null){
            setImgLoading(false);
            let userarray = user.email.split("@");
            let imagename = userarray[0];
            const imgRef = ref(imageDb,`files/${imagename + '_' + v4()}`)
            uploadBytes(imgRef, img).then(value=>{
                console.log(value);
                getDownloadURL(value.ref).then(url=> {
                    setImgUrl(url);
                    const imglist = {'Titelbild' : url};
                    const docRef = setDoc(doc(collectionRef, user && user.uid), imglist, { merge: true });
                    setImgLoading(true);
                    setImgLoadingProcess(true);
                    document.getElementById('file-input').value = '';
                    setTimeout(() => {
                        setImgLoadingProcess(false);
                    }, 2000);
                });
            })
        }
    }

    /*useEffect(() => {
        listAll(ref(imageDb, "files")).then(imgs=>{
            console.log(imgs);
            imgs.items.forEach(val => {
                getDownloadURL(val).then(url=> {
                    setImgUrl(url);
                });
            });
        });
    }, []);*/

  return <>
    <div className='mb-5'>
        <h2 className="text-xl font-bold pb-4">Titelbild:</h2>
        <div className='flex flex-col gap-3 items-center mt-2'>
            <input id='file-input' className='file-input file-input-bordered w-full max-w-xs' type="file" onChange={(e) => setImg(e.target.files[0])} />
            <button className='btn btn-neutral' disabled={!imgLoading || !img ? true : false} onClick={handleImgUpload}>{!imgLoading ? <span className="loading loading-spinner"></span> : null} Hochladen <span>🚀</span></button>
        </div>
    </div>
    {imgLoadingProcess ? <>
        <div id="info-alert" role="alert" className="alert alert-success fixed w-[50%] left-[50%] bottom-[20px] translate-x-[-50%]">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>
            Titelbild erfolgreich gespeichert!
          </span>
        </div>
      </>
      : null
    }
  </>
}

export default ImageUpload