import React, { useEffect, useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { setDoc, doc, collection, getDoc, getDocs, onSnapshot, updateDoc, where, query } from "firebase/firestore";
import { db } from '../firebase';
import ImageUpload from './ImageUpload';

const Account = () => {
  const {user, userid, logout} = useAuth();
  const { img } = ImageUpload();
  const [adresse, setAdresse] = useState('');
  const [street, setStreet] = useState('');
  const [email, setEmail] = useState('');
  const [telefon, setTelefon] = useState('');
  const [internet, setInternet] = useState('');
  const [unternehmen, setUnternehmen] = useState('');
  const [beschreibung, setBeschreibung] = useState('');
  const [singleDoc, setSingleDoc] = useState({});
  const [meilen, setMeilen] = useState([]);
  const [openings, setOpenings] = useState({Montag: '', Dienstag: '', Mittwoch: '', Donnerstag: '', Freitag: '', Samstag: '', Sonntag: ''});
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  //const [list1, setList1] = useState({Benutzer: '', Beschreibung: '', Email: '', Internet: '', Openings: {Montag: '', Dienstag: '', Mittwoch: '', Donnerstag: '', Freitag: '', Samstag: '', Sonntag: ''}, Ort: '', Straße: '', Telefon: '', Unternehmen: '', feldid: '', id: ''});
  
  const collectionRef = collection(db, 'innovationsmeile');
  
  if (localStorage.hasOwnProperty("clientId")) {
      const client_id = localStorage.getItem("clientId");
  }

  /*const dataLoad = async () => {
    try {
      const docRef = await setDoc(doc(collectionRef, user && user.uid), list1, { merge: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }*/

  useEffect(() => {
    const q = query(
      collectionRef,
      where('id', '==', localStorage.getItem("clientId"))
    );

    //dataLoad();

    setLoading(true);
    // const unsub = onSnapshot(collectionRef, (querySnapshot) => {
    const unsub = onSnapshot(q, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setMeilen(items);
      console.log(items);
      setLoading(false);
      setPageLoading(false);
    });
    return () => {
      unsub();
    }
  }, [])

  /*const getData = () => {
    try {
      const unsub = onSnapshot(doc(db, "innovationsmeile", localStorage.getItem("clientId")), (doc) => {
        setSingleDoc(doc.data());
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);*/

  
  /*const getUserData = async () => {
    const innovRef = doc(db, "innovationsmeile", userid);
    const docSnap = await getDoc(innovRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    getUserData();

  }, [userid]);*/

  const navigate = useNavigate();

  const addToDB = async (e) => {
    e.preventDefault();
    let list = {};
    // let ob2 = Object.fromEntries(Object.entries(openings).filter(([_, v]) => v != ''));
    //console.log(singleDoc);
    /*if ( Object.keys(singleDoc).length === 0 ){
      let startobj = {Unternehmen: "", Straße: "", Ort: "", Kontak: "", Email: "", Telefon: "", Internet: "", Beschreibung: ""};
      try {
        const docRef = await setDoc(doc(meileRef, user && user.uid), startobj, { merge: true });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }*/
    list.Openings = meilen[0]['Openings'];
    if(unternehmen !== ''){
      list.Unternehmen = unternehmen;
    }
    if(adresse !== ''){
      list.Ort = adresse;
    }
    if(street !== ''){
      list.Straße = street;
    }
    if(beschreibung !== ''){
      list.Beschreibung = beschreibung;
    }
    if(telefon !== ''){
      list.Telefon = telefon;
    }
    if(email !== ''){
      list.Email = email;
    }
    if(internet !== ''){
      list.Internet = internet;
    }
    if(list.Openings === undefined){
      list.Openings = openings;
    }/*
    if(list.Openings.Montag === undefined || list.Openings.Dienstag === undefined || list.Openings.Mittwoch === undefined || list.Openings.Donnerstag === undefined || list.Openings.Freitag === undefined || list.Openings.Samstag === undefined || list.Openings.Sonntag === undefined){
      list.Openings = openings;
    }*/
    if (openings.Montag !== ''){
      list.Openings.Montag = openings.Montag;
    }
    if (openings.Dienstag !== ''){
      list.Openings.Dienstag = openings.Dienstag;
    }
    if (openings.Mittwoch !== ''){
      list.Openings.Mittwoch = openings.Mittwoch;
    }
    if (openings.Donnerstag !== ''){
      list.Openings.Donnerstag = openings.Donnerstag;
    }
    if (openings.Freitag !== ''){
      list.Openings.Freitag = openings.Freitag;
    }
    if (openings.Samstag !== ''){
      list.Openings.Samstag = openings.Samstag;
    }
    if (openings.Sonntag !== ''){
      list.Openings.Sonntag = openings.Sonntag;
    }

    console.log(list);
    try {
      const docRef = await setDoc(doc(collectionRef, user && user.uid), list, { merge: true });
      const inputs = document.querySelectorAll('.infos-input');
      const editIcons = document.querySelectorAll('.edit-icon');
      for (let i=0; i<inputs.length; i++) {
        inputs[i].value = '';
        inputs[i].classList.add('hidden');
      }
      for (let i=0; i<editIcons.length; i++) {
        editIcons[i].src = 'edit.svg';
      }
      const beschreibung = document.getElementById('beschreibung');
      beschreibung.value = '';
      beschreibung.classList.add('hidden');
      setUpdateData(true);
      setTimeout(() => {
        setUpdateData(false);
      }, 2000);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  
  function handleChange(e) {
    e.preventDefault();
    //e.target.classList.toggle('active');
    e.target.classList.toggle('active');
    e.target.classList.contains('active') ? e.target.src = 'close.svg' : e.target.src = 'edit.svg';
    //console.log(inputElement.current.classList);
    e.target.parentElement.nextSibling.classList.toggle('hidden');
    //e.src = 'close.svg';
  }

  function handleChangeOpenings(e) {
    e.preventDefault();

    e.target.classList.toggle('active');
    e.target.classList.contains('active') ? e.target.src = 'close.svg' : e.target.src = 'edit.svg';
    let openingsinput = document.querySelectorAll('.openings_col input');

    for (let i=0; i<openingsinput.length; i++) {
      openingsinput[i].classList.toggle('hidden');
    }
  }

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("clientId");
      navigate('/?logout=true');
      console.log('You are logged out');
    } catch (e) {
      console.log(e.message);
    }
  }

  return <>
  {!pageLoading ? <>
  {loading ? <h1>Loading ...</h1> : null}
    <div className='w-full text-right px-4 py-2 bg-[#B73538] gap-2 flex flex-col sm:flex-row justify-between items-center'>
      <div>
        <h1 className='font-bold uppercase text-2xl text-white tracking-wide'>Innovationsmeile Bernburg</h1>
      </div>
      <div className='flex flex-row justify-end items-center gap-5 text-white text-[13px] font-normal'>
        <div>
          <p><strong>Benutzer:</strong> {user && user.email}</p>
        </div>
        <button onClick={handleLogout} className='btn btn-default btn-sm'>Logout</button>
      </div>
    </div>
    <div className='w-full text-right px-4 py-1'>
      <p className='text-[#999] text-xs'>Sie brauchen Hilfe? <span className='cursor-pointer hover:text-[#B73538] underline'><Link to='mailto:mail@omazing.de' className='underline' >Support <img className='inline-block align-baseline ml-1' src='external.svg' width='12' height='auto' /></Link></span></p>
    </div>
    <div className='max-w-[1400px] mx-auto mb-20 mt-5 px-5'>
      <form onSubmit={addToDB}>
        <div className='md:flex md:flex-row md:justify-between'>
          <div className='w-full md:w-[33%] border-[#eaeaea] border-r-0 sm:border-r-2 pr-0 sm:pr-[2.5%] pt-2'>
            <h2 className="text-xl font-bold pb-4">Informationen:</h2>
            <div className='flex flex-col py-1'>
              <label className='py-0 font-medium'>Unternehmen:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Unternehmen == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Unternehmen}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
                </> : null}
                <input onChange={(e) => setUnternehmen(e.target.value)} placeholder={singleDoc && singleDoc.Unternehmen} className='infos-input hidden border p-2' type="text" />
              </div>
            </div>
            <div className='flex flex-col flex-1 py-1'>
              <label className='py-0 font-medium'>Straße, Hausnummer:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Straße == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Straße}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
                </> : null}
                <input onChange={(e) => setStreet(e.target.value)} id="street" placeholder={singleDoc && singleDoc.Straße} className='infos-input hidden border p-2' type="text" />
              </div>
            </div>
            <div className='flex flex-col flex-1 py-1'>
              <label className='py-0 font-medium'>PLZ, Ort:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Ort == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Ort}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
                </> : null}
                <input onChange={(e) => setAdresse(e.target.value)} placeholder={singleDoc && singleDoc.Ort} className='infos-input hidden border p-2' type="text" />
              </div>
            </div>
            {/* Kontaktmöglichkeiten */}
            <div className='flex flex-col flex-1 py-1'>
              <label className='py-0 font-medium'>Kontakt Email:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Email == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Email}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
                </> : null}
                <input onChange={(e) => setEmail(e.target.value)} id="street" placeholder={singleDoc && singleDoc.Email} className='infos-input hidden border p-2' type="email" />
              </div>
            </div>
            <div className='flex flex-col flex-1 py-1'>
              <label className='py-0 font-medium'>Kontakt Telefon:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Telefon == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Telefon}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
                </> : null}
                <input onChange={(e) => setTelefon(e.target.value)} placeholder={singleDoc && singleDoc.Telefon} className='infos-input hidden border p-2' type="text" />
              </div>
            </div>
            <div className='flex flex-col flex-1 py-1'>
              <label className='py-0 font-medium'>Internetauftritt &#040;Url&#041;:</label>
              <div className='flex flex-row items-center gap-5'>
                {meilen[0] ? <>
                    <div className='py-3 flex flex-row items-center gap-2'>
                      <p>{meilen[0].Internet == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Internet}</p>
                      <img 
                        onClick={handleChange}
                        className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                        src="edit.svg" />
                    </div>
                </> : null}
                <input onChange={(e) => setInternet(e.target.value)} id="street" placeholder={singleDoc && singleDoc.Internet} className='infos-input hidden border p-2' type="text" />
              </div>
            </div>
            <div className='border-t-[#eaeaea] border mt-6 mb-2'></div>
            {/* Beschreibung */}
            <div className='flex flex-col py-2'>
              <label className='py-0 font-medium'>Beschreibung: <span className='text-[#666] font-normal text-sm'>(max. 150 Zeichen)</span></label>
              {meilen[0] ? <>
                  <div className='py-3 flex flex-row items-center gap-2'>
                    <p>{meilen[0].Beschreibung == '' ? <span className='text-gray-400	'>leer</span> : meilen[0].Beschreibung}</p>
                    <img 
                      onClick={handleChange}
                      className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                      src="edit.svg" />
                  </div>
              </> : null}
              <textarea maxLength="150" id="beschreibung" onChange={(e) => setBeschreibung(e.target.value)} placeholder={singleDoc && singleDoc.Beschreibung} className='hidden border p-2' />
            </div>
          </div>
          <div className='openings_col w-full md:w-[33%] border-r-0 sm:border-r-2 pr-0 sm:pr-[2.5%] sm:px-[2.5%] '>
            {/* Öffnungszeiten */}
            <div className='flex flex-col py-2'>
              <div className='pb-4 flex align-center gap-2'>
                <h2 className="text-xl font-bold">Öffnungszeiten:</h2>
                <img 
                        onClick={handleChangeOpenings}
                        className='edit-icon cursor-pointer w-[20px] h-auto opacity-50 hover:opacity-100'
                        src="edit.svg"
                />
              </div>
              <p className='py-2 text-[#666] text-[13px]'>Bitte im Format 08:00 - 17:00 eingeben! Für geschlossene Tage einfach geschlossen eintragen oder Feld leer lassen</p>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Montag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Montag'] ? meilen[0]['Openings']['Montag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Montag: e.target.value})} id="montag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Dienstag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Dienstag'] ? meilen[0]['Openings']['Dienstag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Dienstag: e.target.value})} id="dienstag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Mittwoch:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Mittwoch'] ? meilen[0]['Openings']['Mittwoch'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Mittwoch: e.target.value})} id="mittwoch" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Donnerstag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Donnerstag'] ? meilen[0]['Openings']['Donnerstag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Donnerstag: e.target.value})} id="donnerstag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Freitag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Freitag'] ? meilen[0]['Openings']['Freitag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Freitag: e.target.value})} id="freitag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Samstag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Samstag'] ? meilen[0]['Openings']['Samstag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Samstag: e.target.value})} id="samstag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
              <div className='py-1'>
                  <label className='py-3 font-medium inline-block w-[100px]'>Sonntag:</label>
                  {meilen[0] && meilen[0]['Openings'] && meilen[0]['Openings']['Sonntag'] ? meilen[0]['Openings']['Sonntag'] : <span className='opacity-[50%] cursor-default'>leer</span>}
                  <input onChange={(e) => setOpenings({...openings, Sonntag: e.target.value})} id="sonntag" className='infos-input hidden border p-2 ml-2 w-32' type="text" />
              </div>
            </div>
          </div>
          <div className='w-full md:w-[33%] sm:px-[2.5%] py-2 flex-col items-start justify-center'>
            <ImageUpload />
          </div>
        </div>
        <button type='submit' className='btn btn-neutral'>Daten aktualisieren</button>
      </form>
      <div className='fixed right-5 bottom-5'>
        <button onClick={()=>document.getElementById('my_modal_1').showModal()} className='btn btn-default'>Vorschau</button>
      </div>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <div className='px-5 py-0'>
            <img className='w-full' src={meilen[0] && meilen[0].Titelbild && meilen[0].Titelbild !== '' ? meilen[0].Titelbild : 'placeholder.jpg'} />
            <div className='px-4 py-5'>
              <h3 className='font-bold text-[#333] text-xl'>{meilen[0] ? meilen[0].Unternehmen : "Unternehmen"}</h3>
              <div className='text-[#666] text-base mt-3'>
                <span>{meilen[0] ? meilen[0].Beschreibung : "Lorem ipsum dolor sit amet..."}<br /></span>
              </div>
              <div className='text-[#666] mt-3'>
                <span>{meilen[0] ? meilen[0].Straße : "Straße"}<br /></span>
                <span>{meilen[0] ? meilen[0].Ort : "Ort"}</span>
              </div>
              <div className='text-[#666]'>
                <span className='text-[#B73538]'>Tel. {meilen[0] ? meilen[0].Telefon : "+49 156 11111111"} | </span>
                <span className='text-[#B73538]'>{meilen[0] ? meilen[0].Email : "lorem@email.com"}</span>
              </div>
            </div>
          </div>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
      {updateData ? <>
        <div id="info-alert" role="alert" className="alert alert-success fixed w-[50%] left-[50%] bottom-[20px] translate-x-[-50%]">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          <span>
            Deine Daten wurden erfolgreich gespeichert!
          </span>
        </div>
      </>
      : null
      }
    </div>
    </> : <h1 className='loading fixed left-[50%] top-[50%]'>Loading...</h1> }
  </>
}

export default Account