import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import base64 from 'base-64';

const SignIn = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const term = searchParams.get("logout");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const {loginUser} = useAuth()
    const navigate = useNavigate();

    const loggedOut = (term == 'true') ? "Du hast dich erfolgreich abgemeldet." : "";

    const addPosts = async () => {
        let username = 'domenik';
        let password = 'WHIr qk9Q IxWy B8Bb 0Cab CHcf';
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + base64.encode(username + ":" + password));
        headers.set('Content-type', 'application/json; charset=UTF-8');
        await fetch('https://2024.bernburg-erleben.de/index.php/wp-json/wp/v2/innovationsmeile', {
        method: 'POST',
        body: JSON.stringify({
           title: 'Created with the React App',
           status: 'publish',
           acf: {
            titel: 'Mein Titel',
            beschreibung: 'This is some Content'
           }
        }),
        headers: headers,
        })
        .then((response) => response.json())
        .catch((err) => {
           console.log(err.message);
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('')
        try{
            await loginUser(email, password);
            navigate('/account')
        } catch (e) {
            setError(e.message);
            console.log(e.message);
        }
        
    }  
    
    return <>
    <div className='max-w-[700px] mx-auto my-16 p-4'>
        <div>
            <p className='text-[#B73538] font-bold'>{loggedOut}</p>
            <h1 className='text-2xl font-bold py-2'>
                Melde dich an
            </h1>
            <p>Du hast noch keinen Account? <Link to='/signup' className='underline' >Jetzt Account beantragen</Link></p>
            <form onSubmit={handleLogin}>
                <div className='flex flex-col py-2'>
                    <label className='py-2 font-medium'>Email Adresse</label>
                    <input onChange={(e) => setEmail(e.target.value)} className='border p-3 rounded-full' type="email" />
                </div>
                <div className='flex flex-col py-2'>
                    <label className='py-2 font-medium'>Passwort</label>
                    <input onChange={(e) => setPassword(e.target.value)} className='border p-3 rounded-full' type="password" />
                </div>
                <button className='border border-[#ed4c4f] bg-[#B73538] hover:bg-[#ed4c4f] w-full p-4 my-2 text-white rounded-full'>Anmelden</button>
            </form>
        </div>
    </div>
    </>
}

export default SignIn