import React from 'react'
import { Link } from 'react-router-dom'

const Signup = () => {
  return (
    <div className='max-w-[700px] mx-auto my-16 p-4'>
      <h2 className='text-2xl font-bold py-2'>Anmeldedaten beantragen</h2>
      <p>Bitte schicken sie uns eine E-Mail an <b><span className='underline cursor-pointer'>omazing@test.com</span></b> oder kontaktieren Sie uns unter <b><span className='underline cursor-pointer'>+49 456 45 44</span></b>, damit wir Ihnen Zugangsdaten für Ihre Property in der Innovationsmeile geben können.</p>
      <p className='my-4 font-bold'>Du hast bereits einen Account? <Link to='/' className='underline text-[#B73538]' >Jetzt anmelden</Link></p>
    </div>
  )
}

export default Signup