import React, { useContext, useState, useEffect } from 'react';
import {signInWithEmailAndPassword, signOut, onAuthStateChanged} from 'firebase/auth';
import {auth} from '../firebase';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext)
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userid, setUserId] = useState('');

  function loginUser(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser);
      setUser(currentUser);
      setUserId(currentUser && currentUser.uid);
      if(currentUser) {localStorage.setItem("clientId", currentUser.uid)};
    })
    return () => {
      unsubscribe();
    };
  }, [])

  const value = {
    loginUser,
    user,
    userid,
    logout
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}