import { Route, Routes } from 'react-router-dom';
import './index.css';
import SignIn from './components/SignIn';
import Account from './components/Account';
import Signup from './components/SignUp';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import PublicRoute from './routes/PublicRoute';
import { Suspense } from 'react';

function App() {
  return (
    <div className="App">
      <Suspense>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<PublicRoute><SignIn /></PublicRoute>} />
            <Route path='/signup' element={<PublicRoute><Signup /></PublicRoute>} />
            <Route path='/account' element={<ProtectedRoute><Account /></ProtectedRoute>} />
          </Routes>
        </AuthProvider>
      </Suspense>
    </div>
  );
}

export default App;
